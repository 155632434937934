<template>
  <iq-card>
    <template v-slot:body>
      <b-overlay :show="isLoading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
              <b-row>
                <b-col sm="4">
                    <ValidationProvider name="Farmer Id" vid="farmer_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="farmer_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('warehouse_service.farmer_id')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        readonly=""
                        id="farmer_id"
                        v-model="rating.farmer_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="WareHouse Id" vid="warehouse_id" >
                    <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="warehouse_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('warehouse_service.warehouse_name')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        readonly=""
                        id="warehouse_id"
                        v-model="warehouse_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Service Rate" vid="service_rate" rules="required|max_value:10|min_value:0">
                      <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="service_rate"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{ $t('warehouseFarmer.service_rate_wh')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          type="number"
                          id="service_rate"
                          v-model="rating.service_rate"
                          placeholder=""
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
<!--                <b-col sm="4">-->
<!--                  <ValidationProvider name="Officer Name" vid="officer_id">-->
<!--                    <b-form-group-->
<!--                        class="row"-->
<!--                        label-cols-sm="12"-->
<!--                        label-for="officer_name"-->
<!--                        slot-scope="{ valid, errors }"-->
<!--                        >-->
<!--                        <template v-slot:label>-->
<!--                        {{ $t('warehouseFarmer.officer_name')}}-->
<!--                        </template>-->
<!--                        <b-form-select-->
<!--                        plain-->
<!--                        v-model="rating.officer_id"-->
<!--                        id="officer_id"-->
<!--                        :state="errors[0] ? false : (valid ? true : null)"-->
<!--                        >-->
<!--                        <template v-slot:first>-->
<!--                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>-->
<!--                        </template>-->
<!--                        </b-form-select>-->
<!--                        <div class="invalid-feedback">-->
<!--                        {{ errors[0] }}-->
<!--                        </div>-->
<!--                    </b-form-group>-->
<!--                  </ValidationProvider>-->
<!--                </b-col>-->
                <b-col sm="4">
                  <ValidationProvider name="Officer Rate" vid="officer_rate" rules="required|max_value:10|min_value:0">
                      <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="officer_rate"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                          {{ $t('warehouseFarmer.officer_rate_wh')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          type="number"
                          id="officer_rate"
                          v-model="rating.officer_rate"
                          placeholder=""
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                      <div class="invalid-feedback">
                          {{ errors[0] }}
                      </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="fiscal Year" vid="fiscal_year_id" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="fiscal Year"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('warehouseFarmer.fiscal_year')}} <span class="text-danger"></span>
                        </template>
                        <b-form-select
                        plain
                        v-model="rating.fiscal_year_id"
                        :options="fiscalList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <b-button type="submit" variant="primary">{{ saveBtnName}}</b-button>
                </b-col>
              </b-row>
        </b-form>
        </ValidationObserver>
      </b-overlay>
    </template>
  </iq-card>
</template>
<script>

import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { serviceRatingStore, farmerProfile } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFarmerRequestData()
      Object.freeze(tmp)
      this.rating = tmp
    }
  },
  mounted () {
    core.index()
    this.getFarmerUserInfo()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      rating: {
          farmer_id: '',
          warehouse_id: 0,
          service_rate: '',
          officer_id: 0,
          officer_rate: '',
          fiscal_year_id: 0
      },
      warehouse_name: '',
      farmerList: [],
      isLoading: false
    }
  },
  computed: {
    fiscalList: function () {
        return this.$store.state.ExternalUserIrrigation.warehouse.fiscalYearList
    },
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    }
  },
  watch: {
    // 'rating.farmer_id': function (newVal, oldVal) {
    //   this.farmerList = this.getWarehouse(newVal)
    // }
  },
  methods: {
    async register () {
      try {
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: false })
        this.isLoading = true
        let result = null
        result = await RestApi.postData(warehouseServiceBaseUrl, serviceRatingStore, this.rating)
        if (result.success) {
          this.$toast.success({
            title: 'Success',
            message: this.id ? 'Data Update successfully' : 'Data saved successfully',
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-4')
          this.isLoading = false
          this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
        } else {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
          this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
        }
      } catch (error) {
          if (error) {
            //
          }
          this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
          this.$toast.error({
          title: 'Error',
          message: 'Something Went wrong! Please, try again.'
        })
        this.isLoading = false
      }
    },
    async getFarmerUserInfo () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true, listReload: false })
      this.isLoading = true
      await RestApi.getData(authServiceBaseUrl, '/auth-user').then(response => {
        if (response.success) {
          this.rating.farmer_id = response.data.user_detail_farmer.mobile_no
          this.rating.warehouse_id = response.data.user_detail_farmer.warehouse_id
          if (this.$i18n.locale === 'bn') {
            this.warehouse_name = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.find(document => document.value === response.data.user_detail_farmer.warehouse_id)?.text_bn
          } else {
            this.warehouse_name = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.find(document => document.value === response.data.user_detail_farmer.warehouse_id)?.text_en
          }
          this.isLoading = false
          this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
        } else {
          this.isLoading = false
          this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
        }
      })
    },
    getWarehouse (farmerId = null) {
        RestApi.getData(authServiceBaseUrl, farmerProfile + '/' + farmerId).then(response => {
        if (response.success) {
          this.rating.warehouse_id = response.data.warehouse_id
            if (this.$i18n.locale === 'bn') {
                this.warehouse_name = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.filter(document => document.value === response.data.warehouse_id)[0].text_bn
            } else {
                this.warehouse_name = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.filter(document => document.value === response.data.warehouse_id)[0].text
            }
        }
      })
    },
    async getWarehouseName (warehouseId) {
        const response = await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-info/list-info', { warehouse_id: warehouseId })
        if (response.success) {
            return this.isBn ? response.data.warehouse_name_bn : response.data.warehouse_name
        } else {
            return 'N/A'
        }
    }
  }
}

</script>

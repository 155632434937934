<template>
  <b-container fluid>
     <iq-card>
      <template v-slot:body>
      <b-row>
      <b-col sm="5">
        <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('warehouse_config.fiscal_year')"
          label-for="year"
        >
          <b-form-select
            plain
            v-model="search.fiscal_year_id"
            :options="fiscalList"
            id="year"
            >
            <template v-slot:first>
            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
         <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </b-col>
    </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouseFarmer.rating_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="listReload">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="ratings" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                      <template v-slot:cell(name)="data">
                        <span class="capitalize">
                          {{ $i18n.locale === 'bn' ? farmerList.filter(document => document.mobile_no === data.item.farmer_id)[0].name_bn : farmerList.filter(document => document.mobile_no === data.item.farmer_id)[0].name}}
                        </span>
                    </template>
                     <template v-slot:cell(year_name_bn)="data">
                    {{ $n(data.item.year_name.split('-')[0], { useGrouping: false })}}-{{$n(data.item.year_name.split('-')[1], { useGrouping: false }) }}
                  </template>
                   <template v-slot:cell(service_rate)="data">
                    {{ $n(data.item.service_rate, { useGrouping: false })}}
                  </template>
                   <template v-slot:cell(officer_rate)="data">
                    {{ $n(data.item.officer_rate, { useGrouping: false })}}
                  </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import FormV from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { serviceRatingList, farmerProfile, userStatus } from '../../../api/routes'

export default {
  name: 'UiDataTable',
  components: {
    FormV
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.ExternalUserIrrigation.static.perPage,
          farmer_id: '',
          warehouse_id: 0,
          service_rate: '',
          officer_id: 0,
          officer_rate: '',
          fiscal_year_id: 0
      },
      search: {
        fiscal_year_id: 0,
        farmer_id: ''
      },
      testId: 0,
      ratings: [],
      farmerList: [],
      rows: []
    }
  },
  computed: {
    fiscalList: function () {
        return this.$store.state.ExternalUserIrrigation.warehouse.fiscalYearList
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('warehouseFarmer.add_rating_entry') : this.$t('warehouseFarmer.add_rating_entry') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    },
    listReload () {
      return this.$store.state.ExternalUserIrrigation.static.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_config.warehouse'), class: 'text-left' },
          { label: this.$t('warehouse_service.farmer_name'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.service_rate'), class: 'text-left' },
          // { label: this.$t('warehouseFarmer.officer'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.officer_rate'), class: 'text-left' },
          { label: this.$t('warehouse_config.fiscal_year'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'warehouse_name_bn' },
          { key: 'farmer_name_bn' },
          { key: 'service_rate' },
          // { key: 'officer_name_bn' },
          { key: 'officer_rate' },
          { key: 'year_name_bn' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'warehouse_name' },
          { key: 'farmer_name' },
          { key: 'service_rate' },
          // { key: 'officer_name' },
          { key: 'officer_rate' },
          { key: 'year_name' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.checkApprovedUser()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
          farmer_id: '',
          warehouse_id: 0,
          service_rate: '',
          officer_id: 0,
          officer_rate: '',
          fiscal_year_id: 0
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
   async loadData () {
     this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const result = await RestApi.getData(authServiceBaseUrl, '/user/farmer-details')
        if (result.success) {
            this.farmerList = result.data
        }
        const response = await RestApi.getData(warehouseServiceBaseUrl, serviceRatingList, params)
        if (response.success) {
           this.ratings = this.formatData(response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
    },
    formatData (data) {
      const formattedData = data.map(item => {
       const warehouseData = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.find(warehouse => warehouse.value === item.warehouse_id)
       const fiscalData = this.$store.state.ExternalUserIrrigation.warehouse.fiscalYearList.find(yearlist => yearlist.value === item.fiscal_year_id)
        const farmarData = this.farmerList.find(listitem => (listitem.username === item.farmer_id) || (listitem.mobile_no === item.farmer_id))
        const tmpBindingData = {
          warehouse_name: warehouseData !== undefined ? warehouseData.text_en : '',
          warehouse_name_bn: warehouseData !== undefined ? warehouseData.text_bn : '',
          year_name: fiscalData !== undefined ? fiscalData.text_en : '',
          year_name_bn: fiscalData !== undefined ? fiscalData.text_bn : '',
          farmer_name: farmarData !== undefined ? farmarData.name : '',
          farmer_name_bn: farmarData !== undefined ? farmarData.name_bn : ''
        }
        return Object.assign({}, item, tmpBindingData)
      })
      return formattedData
    },
    getWarehouse (farmerId = null) {
        RestApi.getData(authServiceBaseUrl, farmerProfile + '/' + farmerId).then(response => {
        if (response.success) {
          this.rating.warehouse_id = response.data.warehouse_id
            if (this.$i18n.locale === 'bn') {
                this.warehouse_name = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.filter(document => document.value === response.data.warehouse_id)[0].text_bn
            } else {
                this.warehouse_name = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.filter(document => document.value === response.data.warehouse_id)[0].text
            }
        }
      })
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
            this.loadData()
        } else {
            this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
